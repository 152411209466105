<template>
  <b-card class="card-transaction" no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="ListIcon" />
        <b-card-title class="ml-25">
          Quick Menu
        </b-card-title>
      </div>
    </b-card-header>

    <b-card-body>
      <a
        v-for="transaction in transactionData"
        :key="transaction.mode"
        class="transaction-item"
        :href="transaction.route"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar rounded size="42" :variant="transaction.avatarVariant">
              <feather-icon size="18" :icon="transaction.avatar" />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h5 class="transaction-title">
              {{ $t(transaction.mode) }}
            </h5>
          </b-media-body>
        </b-media>
      </a>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
  },
  data() {
    return {
      transactionData: [
        {
          mode: "menu_name.meeting_point",
          avatar: "RepeatIcon",
          avatarVariant: "light-primary",
          route: "meeting_points",
        },
        {
          mode: "menu_name.conference",
          avatar: "VideoIcon",
          avatarVariant: "light-success",
          route: "conference",
        },
        {
          mode: "menu_name.stand",
          avatar: "GridIcon",
          avatarVariant: "light-danger",
          route: "stands",
        },
        {
          mode: "menu_name.notication",
          avatar: "BellIcon",
          avatarVariant: "light-warning",
          route: "notifications",
        },
      ],
    };
  },
  created() {
    let user = this.getUser();
    if (user.role == "admin") {
      let stataLInk = {
        mode: "Statistiche",
        avatar: "ActivityIcon",
        avatarVariant: "light-info",
        route: "statistics",
      };
      this.transactionData.push(stataLInk);
    }
  },
  methods: {
    getUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
