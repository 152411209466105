import { api } from "./index";

export const getAllNotifications = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`notifications`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getNotifications = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`notifications/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const createNotifications = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`notifications`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const updateNotifications = (id, formData) => {
  return new Promise((resolve, reject) => {
    api
      .put(`notifications/${id}`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const deleteNotifications = (id) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`notifications/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
