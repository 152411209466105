<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex align-items-center">
        <feather-icon size="24" class="mr-1" icon="BellIcon" />
        <b-card-title class="ml-25">
          {{ $t("menu_name.notication") }}
        </b-card-title>
      </div>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline>
        <app-timeline-item
          variant="warning"
          v-for="(notification, index) in notifications"
          :key="index"
        >
          <div
            class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
          >
            <h6>
              <font-awesome-icon :icon="['fas', notification.icon]" />
              <font-awesome-icon :icon="['far', notification.icon]" />
              <font-awesome-icon :icon="['fab', notification.icon]" />
              {{ notification.title }}
            </h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{
              notification.time
            }}</small>
          </div>
          <p>{{ notification.description }}</p>
        </app-timeline-item>
      </app-timeline>
      <!--/ timeline -->
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="warning"
        href="notifications"
      >
        {{ $t("dashboard.go_notification") }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BButton,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import { getAllNotifications } from "@api/notifications";

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BButton,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  methods: {
    getUser() {
      return this.$store.state.auth.user;
    },
    getNotification() {
      const $this = this;
      getAllNotifications()
        .then((data) => {
          $this.notifications = data.map((item) => {
            return {
              id: item.id,
              icon: item.icon,
              title: item.title,
              description: item.description,
              time: item.time,
            };
          });
          $this.notifications = $this.notifications.sort((a, b) =>
            a.time < b.time ? 1 : -1
          );
          $this.notifications = $this.notifications.slice(0, 5);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    let user = this.getUser();
    this.getNotification();
  },
  data() {
    return {
      notifications: [],
    };
  },
};
</script>
