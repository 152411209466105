import { api } from "./index";

export const getHalls = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`conference_halls`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getAllConferences = () => {
  return new Promise((resolve, reject) => {
    api
      .get(`conferences`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const getConferences = (id) => {
  return new Promise((resolve, reject) => {
    api
      .get(`conferences/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const createConferences = (formData) => {
  return new Promise((resolve, reject) => {
    api
      .post(`conferences`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const updateConferences = (id, formData) => {
  return new Promise((resolve, reject) => {
    api
      .put(`conferences/${id}`, formData)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};

export const deleteConferences = (id) => {
  return new Promise((resolve, reject) => {
    api
      .delete(`conferences/${id}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response: { data } }) => reject(data));
  });
};
